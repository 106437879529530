import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'

dayjs().format()
dayjs.extend(calendar)

export const signInEventId = 'admin'

export function formatDate(timestamp) {
  const date = new Date(timestamp);
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  return date.toLocaleDateString("en-US", options);


  //  debugger;
  // return dayjs().calendar(dayjs(date),{
  //   sameDay: '[Today at] h:mm A', // The same day ( Today at 2:30 AM )
  //   nextDay: '[Tomorrow at] h:mm A', // The next day ( Tomorrow at 2:30 AM )
  //   nextWeek: 'dddd [at] h:mm A', // The next week ( Sunday at 2:30 AM )
  //   lastDay: '[Yesterday at] h:mm A', // The day before ( Yesterday at 2:30 AM )
  //   lastWeek: '[Last] dddd [at] h:mm A', // Last week ( Last Monday at 2:30 AM )
  //   sameElse: 'MMMM DD' // Everything else ( 17/10/2011 )
  // })

}


export const timeAgo = (timeDiff) => {
  let timeAgo;
  if (timeDiff < 60000) {
    timeAgo = "Less than a minute ago";
  } else if (timeDiff < 3600000) {
    const minutes = Math.floor(timeDiff / 60000);
    timeAgo = `${minutes} min${minutes > 1 ? "s" : ""} ago`;
  } else if (timeDiff < 86400000) {
    const hours = Math.floor(timeDiff / 3600000);
    timeAgo = `${hours} hr${hours > 1 ? "s" : ""} ago`;
  } else if (timeDiff < 172800000) {
    timeAgo = "Yesterday";
  } else {
    const date = new Date(timeDiff);
    const formattedDate = new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric" }).format(date);
    timeAgo = formattedDate;
  }
  return <div>{timeAgo}</div>;
};
