import React, { useState, useEffect } from 'react';
import EventForm from './EventForm';
import {db, auth }  from '../firebase';
import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { formatDate } from '../utils';
import { useStore } from '../store';
import {signInEventId} from '../utils';


export default function MyEvents() {
  const [visible, setVisible] = useState(false);
  const user = useStore(state => state.user);
  function handleAddEvent() {
    setVisible(true);
  }
  function handleHideForm() {
    setVisible(false);
  }

  const [events, setEvents] = useState([]);


  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if(!userId) return;
    const eventsCollectionRef = collection(db, 'events');
    const eventsQuery = query(eventsCollectionRef,
      where('userId', '==', userId))
      // To Do: this line is breaking the query
      // orderBy('date', 'desc') );



    const unsubscribe = onSnapshot(eventsQuery, (snapshot) => {
      const eventsData = snapshot.docs?.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      setEvents(eventsData.filter(event => event.id !== signInEventId));
    });

    return () => {
      unsubscribe(); // Unsubscribe from the snapshot listener when the component unmounts
    };
  }, []);


  return (

    <div className='flex flex-1 flex-col px-10'>
      <div className="flex flex-row justify-between items-center py-4 uppercase text-lg font-semibold h-fit">
          <div className='flex flex-row items-center'>
          <div className="py-4 pr-6 h-fit">Your Events</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleAddEvent}
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 flex my-auto opacity-50 cursor-pointer hover:opacity-100"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
          </div>
      </div>
      <div className='grid sm:flex-1 sm:grid-cols-3 gap-10'>
        {!events.length ?
        <div className='text-base p-4 text-darkGray text-center'>Invite your friends without the hassle.</div> : null}
        {events?.map((event) => (
         <Link to={`/flock/${event.id}`} className='w-fit'>
            <div
            key={event.id}
            className='w-60 h-56  hover:opacity-80 bg-center  p-6 shadow text-white font-semibold text-2xl cursor-pointer hover:relative'
            style={{ backgroundImage: `url(${event.image})`, backgroundSize: 'cover'}}
            >
            </div>
            <div className='flex flex-col text-black'>
              <div className='uppercase'>
                {event.title}
              </div>
              <div className=''>
                {formatDate(event.date?.toDate())}
              </div>
              <div className='text-sm'>
                {event.time}
              </div>

            </div>
          </Link>
        ))}
    </div>
      {visible && <EventForm onHideForm={handleHideForm} />}
    </div>
  );
}
