import { create } from 'zustand'

export const useStore = create((set, get) => ({
  user: null,
  events: {},
  // curEvent: null,
  userName: '',
  isJoined: false,

  currentMessage: '',
  setCurrentMessage: (message) => set(state => ({ currentMessage: message })),

  currentName: '',
  setCurrentName: (name) => set(state => ({ currentName: name })),

  setUser: (user) => set(state => ({user: user})),
  setEvents: (events) => set(state => ({events: events})),
  setUserName: (name) => set(state => ({user: {...state.user, name: name}})),
  // setCurEvent:(event) => set(state => ({events: {...state.events, [event.id]: event}})),
  isUserInEvent: (eventId) => {
    get().events[eventId] && get().events[eventId].users.includes(get().user.uid)
  },
}))
