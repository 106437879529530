import React, { useEffect } from "react";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { Outlet, useNavigate } from "react-router-dom";
import LeftSideBar from "../components/LeftSideBar";
import { Link } from "react-router-dom";
import { useRef } from "react";

import flock_text from "../components/flock_text.png";
import { useStore } from "../store";

import { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import { doc, collection } from "firebase/firestore";
import { db } from "../firebase";
import { updateDoc } from "firebase/firestore";


export default function Home({ user }) {
  const events = useStore((state) => state.events);
  const setEvents = useStore((state) => state.setEvents);


  const [profilePhoto, setProfilePhoto] = useState(null);
  const [selectedFile, setSelectedFile] = useState(false);
  const [addPhoto, setAddPhoto] = useState(false);
  const [hasProfilePhoto, setHasProfilePhoto] = useState(false);
  const [pressDropdown, setPressDropdown] = useState(false);
  const dropdownRef = useRef(null);



  useEffect(() => {
    if (user.profilePhoto) {
      setHasProfilePhoto(true);
    }
  }, [user.profilePhoto]);

  const handleAddProfilePhoto = (event) => {
    event.preventDefault();
    setSelectedFile(false);

    console.log(profilePhoto, "profilePhoto");
    const storageRef = ref(storage, `images/${profilePhoto.name}`);
    const uploadTask = uploadBytesResumable(storageRef, profilePhoto);

    try {
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          // eslint-disable-next-line default-case
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          // Handle upload errors
          console.error('Error uploading file: ', error);
        },
        async () => {
          // Upload completed successfully, now we can get the download URL
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('File available at', downloadURL);

          console.log(user, "user");
          const userId = user.id ? user.id : user.uid;
          const userRef = doc(collection(db, "users"), userId);
          try {
            await updateDoc(userRef, {
              profilePhoto: downloadURL,
            });

            const updatedUser = {
              ...user,
              profilePhoto: downloadURL,
            };
            console.log(updatedUser, "updated user");
            setAddPhoto(false);


            // Create an event in the Firestore collection

          } catch (error) {
            console.error("Error updating profile photo:", error);
          }
        }
      );
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };




  // console.log(events, "events in home")

  const navigate = useNavigate();
  const handleSignOut = async () => {
    try {
      console.log('here');

      await signOut(auth);
      console.log('here');
      navigate("/");
      console.log('here');
    } catch (error) {
      console.error(error);
    }
  };

  const updateProfilePhoto = (event) => {
    setProfilePhoto(event.target.files[0]);
    setSelectedFile(true);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setPressDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  return (
    <>
     <header className="border-black py-4 sm:px-10 sm:pl-8 px-4 flex flex-row border-b border-lightGray items-center justify-between w-full">
    <div className="w-full flex flex-row justify-between items-center">
      <Link to="/flock" >
        <img src={flock_text} alt={"flock"} className="h-10 w-18"/>
      </Link>


    <div className="flex flex-row justify-center items-center">
      {/* <div onClick={() =>
        setPressDropdown(!pressDropdown) }
        className="pr-4">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
      </svg>
      </div> */}
    <div className="flex flex-col relative">
      <div className="flex flex-row items-center">
        {/* <div className="mr-4 opacity-60">{user.displayName}</div> */}
        <div
          className="flex flex-col items-center ml-auto mr-4"
          title="add profile photo"
          onClick={()  => setAddPhoto(!addPhoto)}
        >
          {hasProfilePhoto ? (
            <div
              style={{ backgroundImage: `url(${user.profilePhoto})` }}
              className="bg-lightGray bg-cover border-black bg-center cursor-pointer rounded-full w-[2.5rem] h-10 flex justify-center items-center"
            ></div>
          ) : (
            <div className="bg-lightGray cursor-pointer rounded-full w-[2.5rem] h-10 flex justify-center items-center w-[2.5rem] upp">
              {user.displayName[0]}
            </div>
          )}
        </div>
      </div>

      {pressDropdown && (
      <div className="absolute right-0 top-[40px] mt-2 min-w-[150px] p-2 shadow-md"
        ref={dropdownRef}>
        <div className="bg-white rounded ">
          <div
            className="flex items-center p-2 cursor-pointer hover:bg-lightGray"
            onClick={() => setAddPhoto(true)}
          >
            Profile Photo
          </div>
          <div className="p-2 cursor-pointer hover:bg-lightGray">
            <Link to="my-events" >My Events</Link>
          </div>
          <div className="p-2 cursor-pointer hover:bg-lightGray">
             <Link to="explore">Explore</Link>
          </div>
          <div className="p-2 cursor-pointer hover:bg-lightGray">
            <Link to="upcoming">Attending</Link>
          </div>
          <div
            className="flex items-center p-2 cursor-pointer hover:bg-lightGray"
            onClick={handleSignOut}
          >
            Log Out
          </div>
        </div>
      </div>
      )}
    </div>
  </div>
  </div>
</header>

      <div className="grid sm:flex-1 sm:grid-cols-[200px_minmax(900px,_1fr)_100px]">
        <LeftSideBar user={user} />
        <Outlet />
      </div>
      {addPhoto && (
        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex flex-col justify-center items-center'>
          <div className='shadow-inner bg-white w-[500px] h-[500px] rounded-[20px] flex flex-col justify-center items-center sm:w-[45%] w-[85%]  relative'>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setAddPhoto(false)}
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 absolute top-2 right-2 cursor-pointer"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <div className='relative '>
              <div className='bg-lightGray bg-cover bg-center w-[80px] h-[80px] rounded-full flex justify-center items-center mx-auto'
                style={{ backgroundImage: `url(${user?.profilePhoto})`}}>
                {!user.profilePhoto && !user.user?.profilePhoto &&(
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-500"
                  >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
                )}
              </div>
            </div>
            <div className='text-2xl font-semibold my-4'>{user.displayName}</div>
            <div className='text-center opacity-60 py-2 px-2'>
              Add a 📷 so your friends can recognize you!
            </div>
            <input type="file"
            onChange={updateProfilePhoto}
            className="flex flex-row items-center justify-center text-sm mt-2 text-slate-500 mx-auto pl-12
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-violet-50 file:text-slate-700
            hover:file:bg-violet-100
          "/>
            <button className='text-white font-medium border-2 bg-pink px-4 py-2 rounded-[20px] mt-16
             disabled:bg-gray-300
             disabled:cursor-not-allowed'
            onClick={handleAddProfilePhoto}
            disabled={!selectedFile} >Add Photo</button>
          </div>
        </div>
      )}
    </>
  );
}
