import { Link, Outlet } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";



export default function LeftSideBar({user}){

  const navigate = useNavigate();
    const handleSignOut = async () => {
      try {
        console.log('here');

        await signOut(auth);
        console.log('here');
        navigate("/");
        console.log('here');
      } catch (error) {
        console.error(error);
      }
    };

  return(
    <div className="sm:text text-sm sm:h-screen h-fit bg-lightGray
     flex flex-1 sm:flex-col flex-row sm:gap-4 justify-between
     sm:justify-start sm:w-fit w-full sm:px-10 px-4 sm:pt-24
     pt-10 ">
      <Link to="upcoming" className="uppercase pb-10 opacity-50 hover:opacity-100">attending</Link>
      <Link to="explore" className="uppercase pb-10 opacity-50 hover:opacity-100">explore</Link>
      <Link to="my-events" className="uppercase pb-10 opacity-50 hover:opacity-100">your events</Link>
      <div className="uppercase pb-10 opacity-50 hover:opacity-100" onClick={handleSignOut} >log out</div>
    </div>
  )
}
