import { auth, db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  Timestamp,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import {Plane} from '../App';
import { Link, Navigate } from "react-router-dom";
import { useParams} from "react-router-dom";
import { motion } from "framer-motion";
import "./externalEvent.css";
import PhoneVerificationForm from "./PhoneVerificationForm";
import classNames from "classnames";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import ReactMarkdown from "react-markdown";
import { GrUploadOption, GrUserAdmin } from "react-icons/gr";
import { FiUploadCloud } from "react-icons/fi";
import { IoMdCloseCircleOutline } from "react-icons/io";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import { useRef } from "react";
import { useStore } from "../store";
import {
  addUserToEvent,
  addEventToUser,
  getUserProfilePhoto,
  getAttendeeNumbers,
  addMessageToCollection
} from "../firebase.api";

import { formatDate } from "../utils";
import top_right_flock from "./topright_back.png";
import flock_text from "./flock_text.png";

import _, { get } from "lodash";
import { timeAgo } from "../utils";

import { Tooltip } from "react-tooltip";

import {animateScroll } from 'react-scroll'
import { BsFillArrowUpCircleFill  } from "react-icons/bs";
import { BsFillArrowDownCircleFill  } from "react-icons/bs";

import { useLocation } from 'react-router-dom';

dayjs.extend(calendar);
window.dayjs = dayjs;

export default function ExternalEvent() {
  const now = new Date();

  const user = useStore((state) => state.user);
  let userId = user?.id ? user?.id : user?.uid;

  window.state = useStore((state) => state);

  const currentMessage = useStore((state) => state.currentMessage);
  const setCurrentMessage = useStore((state) => state.setCurrentMessage);

  const [verifyPhone, setVerifyPhone] = useState(false);

  const { eventId } = useParams();
  const [eventData, setEventData] = useState(null);

  const isUserInEvent = eventData?.attending?.some((a) => a.userId === userId);
  const [host, setHost] = useState(null);

  const [tooltipOpen, setTooltipOpen] = useState(true);
  const [attendeeProfile, setAttendeeProfile] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [openAttendingList, setOpenAttendingList] = useState(false);


  // check if event is a LOG IN event
  const params = useParams();
  let isLoginEvent;
  for (const key in params) {
    if (params[key] === "admin") {
      isLoginEvent = true;
    }
  }

  async function sendCurrentMessage(u) {
    //u is the user object coming back from the verification form since the user state isn't updated yet
    await addUser(u || user, eventId, currentMessage);
  }

  async function joinClicked() {
    // fork, is the user logged in?
    // if so, add user to event
    if (user) {
      sendCurrentMessage();
    } else {
      // if not, show phone verification form
      setVerifyPhone(true);
    }
  }

  async function addUser(user, eventId, currentMessage) {
    if (user && eventId) {
      await addUserToEvent(user, eventId, currentMessage);
      await addEventToUser(user, eventId);
    }
    if(userId === host.id){
      // twilio message to all attendees
      console.log("need to send twilio message to all attendees")
      const attendeesIds = eventData.attending.map((a) => a.userId);
      const attendeeNumbers = await getAttendeeNumbers(attendeesIds);
      console.log(attendeeNumbers, "attendeeNumbers after call")
      await addMessageToCollection(attendeeNumbers, currentMessage, eventId, host);
    }
  }

  // onMount
  const location = useLocation();
  const bottomRef = useRef(null);

  function scrollToBottom() {
        setTimeout(() => window.scrollTo({
          top: 100000000,
          left: 0,
          behavior: "smooth",
        }), 400);

    // setTimeout(() => bottomRef.current?.scrollIntoView({block:'end', behavior: "smooth" }), 0);
}

useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    if (searchParams.get("b")) {
        scrollToBottom();
    }
}, [eventData]);

  function renderAttendee(attendee, i ) {
    return (
      <motion.div animate={{ y: -30 }} className={classNames(
      "grid",
        // !isUserInEvent ? "blur-sm": ""
      )}>
        <div
          className={classNames(
            "text-xs text-darkGray ml-[45px] mt-2 mr-5 pb-[3px] flex flex-row",
            attendee.userId === userId
              ? "justify-self-end mr-[60px]"
              : "ml-[79px]"
          )}
        >
          <div>{attendee.userId === userId ? 'You' : attendee.displayName?.split(" ")[0]} ·&nbsp;</div>
          {attendee.userId === host?.id ? <span className='text-xs opacity-50 font-semibold'>host ·&nbsp;</span> : ''}
          <div className="text-xs opacity-50 justify-self-end mr-5 pb-[3px]">
            {timeAgo(now.getTime() - attendee?.joinedAt?.toDate().getTime())}
          </div>
        </div>
        <div
          className={classNames(
            "w-fit mb-2 text-[17px] textGreen flex flex-row justify-center items-center ",
            attendee.userId === userId ? "right" : "left"
          )}
        >
          {attendee.userId !== userId && (
            <div
              style={{
                backgroundImage: `url(${
                  attendee?.profilePhoto || attendee.user?.profilePhoto
                })`,
              }}
              className="w-8 h-8 bg-center rounded-full bg-cover mr-[-20px] ml-[20px] z-50 cursor-pointer bg-lightGray flex items-center justify-center"
              onClick={() => {
                setAttendeeProfile(attendee);
                setOpenProfile(true);
              }}
            >
              {!attendee?.profilePhoto && (
                <div className="uppercase opacity-70">
                  {attendee.displayName?.slice(0, 1)}
                </div>
              )}
            </div>
          )}
          <div
            className={classNames(
              "bubble w-fit mb-2 text-[17px] texx-darkGray",
              attendee.userId === userId ? "right" : "left",
              attendee.userId === host?.id ? "host" : ""
            )}
            key={attendee.userId}
          >
            {attendee.message}
          </div>
          {attendee.userId === userId && (
            <>
              <div className="tooltip-container z-40">
                {!attendee?.profilePhoto && (
                  <Tooltip
                    className="my-tooltip"
                    onClick={() => setAddPhoto(true)}
                    id="my-tooltip"
                    isOpen={tooltipOpen}
                    afterShow={() => {
                      setTimeout(() => setTooltipOpen(false), 5000);
                    }}
                  />
                )}
                <div
                  onClick={() => setAddPhoto(true)}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Upload your face!"
                  style={{
                    backgroundImage: `url(${
                      attendee?.profilePhoto || attendee.user?.profilePhoto
                    })`,
                  }}
                  className="w-8 h-8 bg-center bg-black
                  cursor-pointer rounded-full bg-cover ml-[-20px]
                  shadow-md z-50 mr-[20px] bg-lightGray flex
                    items-center justify-center"
                >
                  {!attendee?.profilePhoto && (
                    <motion.div
                      animate={{
                        scale: [1, 2, 2, 1, 1],
                        rotate: [0, 0, 270, 270, 0],
                        borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                      }}
                    >
                      <FiUploadCloud className="text-2xl text-pink" />
                    </motion.div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </motion.div>
    );
  }

  function renderSendInput() {
    return !isUserInEvent && host?.id !== userId ? (
      <motion.div layout key="instruction" className={classNames(
        "sticky-bottom z-50",
        verifyPhone ? 'secure' : ''
      )}>
        {!verifyPhone &&  (
          <>
            <div className="text-[17px] text-slate-600 font-medium mx-auto w-fit my-2">
              {user ? (
                <div className="pb-4 font-semibold" display="block">
                  👋 &nbsp;{user.displayName.split(" ")[0]}!
                </div>
              ) : null}
              {
                <div display="block">
                  {isLoginEvent ?(
                    'Send us a 👍 to sign up or log in.'
                  ):(
                    <>{
                      !user &&(
                      <><span> 👋 Hi there. </span><br /></>
                      )}
                      <br/>
                      <span className='font-bold '>Send a message</span><span> to RSVP and join the 🧵.</span>
                    </>
                    )
                  }
                </div>
              }
            </div>
            <form>
              <div
                className="border bg-white border-[1.5px] border-mediumGray text-darkGray
                  border-2 p-2 text-[18px] rounded-[20px] w-[95%] shadow-inner flex flex-row justify-between mx-auto"
              >
                <input
                  type="text"
                  className="focus:outline-none w-full pl-4 text-black bg-[]"
                  placeholder={isLoginEvent? ('👍'):("Type your message here... ")}
                  value={currentMessage}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    setCurrentMessage(inputValue);
                  }}
                />
                <button
                  type="submit"
                  className="hover:text-black"
                  onClick={(e) => {
                    e.preventDefault();
                    joinClicked();
                  }}
                >
                  <Plane/>
                </button>
              </div>
            </form>
          </>
        )}
        {verifyPhone && (
            <PhoneVerificationForm
              onFinish={(user) => sendCurrentMessage(user)}
            />
        )}
      </motion.div>
    ) : (
      <>
      {/* {!isLoginEvent && ( */}
        <motion.div layout key="instruction" className="sticky-bottom">
          <form key="instruction_input_container_form">
            {isLoginEvent &&(
            < div className="flex flex-row items-center w-full justify-center ">
                <span className="flex items-center w-fit">
                </span>
                <span>
                    <Link to="/home">
                      <div className="w-fit flex justify-end mt-2 pr-4">
                        <img
                          style={{ zIndex: -2 }}
                          className="w-[80px]"
                          src={flock_text}
                          alt="home" />
                      </div>
                    </Link>
                  </span>
                  </div>
            )}
              {!isLoginEvent && (
                <div
                  key="instruction_input_container"
                  className="border bg-white border-[1.5px] border-mediumGray text-darkGray
              border-2 p-2 text-[18px] rounded-[20px] w-[95%] shadow-inner flex flex-row justify-between mx-auto"
                >
              <input
                    key="instruction_input"
                    type="text"
                    className="focus:outline-none w-full pl-4 text-black bg-[]"
                    placeholder="Type a message... "
                    value={currentMessage}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      setCurrentMessage(inputValue);
                    } } /><button
                      type="submit"
                      className="hover:text-black"
                      onClick={(e) => {
                        e.preventDefault();
                        joinClicked();
                      } }
                    >
                      <Plane />
                    </button>
              </div>
             )}
          </form>
        </motion.div>
        {/* // )} */}
      </>
    );
  }

  //onMount

  useEffect(() => {
    if (eventId) {
      const fetchEvent = async () => {
        onSnapshot(doc(collection(db, "events"), eventId), async (snapshot) => {
          const event = snapshot.data();
          if (event?.attending) {
            await Promise.all(
              event.attending?.map(async (a) => {
                const userRef = doc(collection(db, "users"), a.userId);
                const userDoc = await getDoc(userRef);
                const user = userDoc?.data();
                a.displayName = user?.displayName;
                a.profilePhoto = user?.profilePhoto;
              })
            );
          }
          setEventData(event);

          const scrollHeight = document.body.scrollHeight;
          setTimeout(() => {
            window.scrollTo(0, scrollHeight);
            setCurrentMessage("");
          }, 2);
        });
      };
      fetchEvent();
    }
  }, [eventId, user]);

  useEffect(() => {
    async function getHost() {
      const hostId = eventData?.userId;
      if (hostId) {
        const hostRef = doc(collection(db, "users"), hostId);
        const hostSnapshot = await getDoc(hostRef);
        const hostData = hostSnapshot.data();
        setHost(hostData);
      }
    }

    getHost();
  }, [eventData]);

  // Adding profile photo on the external page

  const [addPhoto, setAddPhoto] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [selectedFile, setSelectedFile] = useState(false);

  const updateProfilePhoto = (event) => {
    setProfilePhoto(event.target.files[0]);
    setSelectedFile(true);
  };

  const handleAddProfilePhoto = (event) => {
    event.preventDefault();
    setSelectedFile(false);

    const storageRef = ref(storage, `images/${userId}/${profilePhoto.name}`);
    const uploadTask = uploadBytesResumable(storageRef, profilePhoto);

    try {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          // eslint-disable-next-line default-case
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // Handle upload errors
          console.error("Error uploading file: ", error);
        },
        async () => {
          // Upload completed successfully, now we can get the download URL
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("File available at", downloadURL);

          console.log(user, "user");
          const userId = user.id ? user.id : user.uid;
          const userRef = doc(collection(db, "users"), userId);
          const splits = downloadURL.split('.');
          try {
            await updateDoc(userRef, {
              profilePhoto: splits.slice(0, -1).join('.') +'_700x700.'+_.last(splits),
            });

            const updatedUser = {
              ...user,
              profilePhoto: downloadURL,
            };
            console.log(updatedUser, "updated user");
            setTimeout(2000, setAddPhoto(false));

            // Create an event in the Firestore collection
          } catch (error) {
            console.error("Error updating profile photo:", error);
          }
        }
      );
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };


  // scroll buttons
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [showScrollBottom, setShowScrollBottom] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      // Show "scroll to top" button if more than 2000px from the top
      setShowScrollTop(window.scrollY > 1000);

      // Show "scroll to bottom" button if more than 2000px from the bottom
      setShowScrollBottom(
        window.scrollY < document.documentElement.scrollHeight - window.innerHeight - 1000
      );
    };

    // Add scroll event listener when component mounts
    checkScroll()
    window.addEventListener("scroll", checkScroll);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []); // Empty array means this effect runs once on mount and clean up on unmount


  if (!eventData) {
    return <></>;
  }

  const attending = eventData?.attending || [];
  const attendingCount = _.uniq(attending.map((a) => a.userId)).filter(
    (a) => a !== host?.id
  ).length;

  const uniqueAttending = attending.filter((item, index, array) => {
    return array.findIndex((i) => i.userId === item.userId) === index && item.displayName;
  });

  return (
    <>
      <div className="main-content min-h-[100vh] max-w-[400px] m-auto">
        <div className="flex flex-row justify-between pl-2">
          <Link to="/">
          <div className="w-full flex justify-end mt-2 pl-8 pr-4 opacity-10 top-image pb-8">
              <img
                style={{ zIndex: -2 }}
                className="w-[200px]"
                src={flock_text}
                alt="home"
              />
            </div>
          </Link>
          {/* <Link to="/">
            <div className="w-full flex justify-end mt-2 pr-4 opacity-100">
              <img
                style={{ zIndex: -2 }}
                className="w-[105px]"
                src={top_right_flock}
                alt="home"
              />
            </div>
          </Link> */}
        </div>
        <div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="mt-[-70px]"
          >
            <div className="text-xs opacity-50 ml-[80px] mb-[3px]">
              {host?.displayName.trim()}&nbsp;·&nbsp;<b>hosting</b>
            </div>
            <div className="w-fit mb-2 text-[17px] textGreen flex flex-row justify-center items-end right">
              <div
                style={{
                  backgroundImage: `url(${
                    host?.profilePhoto || host?.user?.profilePhoto
                  })`,
                }}
                onClick={() => {
                  setAttendeeProfile(host);
                  setOpenProfile(true);
                }}
                className="w-8 h-8 bg-center rounded-full cursor-pointer z-10 bg-cover mr-[-20px] ml-[20px] bg-lightGray flex items-center mb-[22px] justify-center"
              >
                {!host?.profilePhoto && !host?.user?.profilePhoto && (
                  <div className="uppercase opacity-70">
                    {host?.displayName.slice(0, 1)}
                  </div>
                )}
              </div>

              <div className="bubble host left width-[300px] mb-4 textGreen text-[17px]">
              <div>
                  <ReactMarkdown className="markdown">
                    {eventData.description}
                  </ReactMarkdown>
                  {!isLoginEvent &&(
                  <>
                      <span>📍 </span>
                      <Link
                      to={`https://www.google.com/maps/place/+${eventData.location
                        .split(" ")
                        .join("+")}`}
                      target="_blank"
                    >
                      <span className="font-semibold cursor-pointer underline underline-offset-2">
                        {eventData.location}
                      </span>
                    </Link>
                    </>
                  )}
                  <p className="font-semibold">
                    {isLoginEvent ? (
                        ''
                    ): (

                      '🗓 ' + formatDate(eventData.date.toDate())
                    )}
                  </p>
                  <p className="font-semibold">
                    {
                      isLoginEvent
                        ? ''
                        : '🕐 ' + (eventData.time.startsWith('0') ? eventData.time.substring(1) : eventData.time)
                    }
                  </p>
                </div>
              </div>
            </div>
            {!isLoginEvent && (
            <div className="w-fit mb-2 text-[17px] text-darkGray flex flex-row justify-center items-end right">
              <div
                onClick={() => {
                  setAttendeeProfile(host);
                  setOpenProfile(true);
                }}
                style={{
                  backgroundImage: `url(${
                    host?.profilePhoto || host?.user?.profilePhoto
                  })`,
                }}
                className="w-8 h-8 z-10 cursor-pointer bg-center rounded-full bg-cover mr-[-20px] ml-[20px] bg-lightGray flex items-center mb-[22px] justify-center"
              >
                {!host?.profilePhoto && !host?.user?.profilePhoto && (
                  <div className="uppercase opacity-70">
                    {host?.displayName.slice(0, 1)}
                  </div>
                )}
              </div>
              <div className="bubble host image left mb-4">
                <div
                    className="h-60 w-[238px] bg-cover bg-center rounded-lg"
                    style={{
                      backgroundImage: `url(${eventData.image})`,
                    }}
                  />
              </div>
            </div>
            )}
          </motion.div>
          <div>

      {showScrollTop && (
        <motion.div className=" up sm:right-[200px] right-[10px] sm:w-[467px] w-full m-auto flex flex-cols items-center">
        <button
          className="opacity-20 invert-[.8] text-[35px]"
          onClick={() => animateScroll.scrollToTop()}
        >
          <BsFillArrowUpCircleFill />
        </button>
        </motion.div>
      )}

      {showScrollBottom && (
        <motion.div className={classNames(
          "arrow-container sm:right-[200px] right-[10px] sm:w-[467px] w-full m-auto flex flex-cols items-center",
          !showScrollBottom ? 'bottom-[100px]' : ''
        )}>
          <button
            className="opacity-20 invert-[.8] text-[35px]"
            onClick={() => animateScroll.scrollToBottom()}
          >
            <BsFillArrowDownCircleFill />
          </button>
        </motion.div>
      )}


    </div>
          {attending.length > 0 && !isLoginEvent && (
            <>
              <div
                className="text-sm text-dakrkGray
            opacity-50 flex w-[90%] mx-auto
             mt-[25px] mb-[40px] justify-center font-semibold"
              >
                {attendingCount > 0 && (
                  <div className="">
                    <span className=" underline underline-offset-2 cursor-pointer"
                    onClick={()=>{
                      setOpenAttendingList(true)
                    }}
                    >{attendingCount} going</span>  ·{" "}
                    {isUserInEvent && userId !== host?.id && "You, "}
                    {uniqueAttending
                      // .filter((a) => a.userId !== userId) // Filter out elements where a.userId equals userId
                      .map((a) => a.displayName?.split(" ")[0])
                      .slice(0, 2)
                      .join(", ")}
                    {attendingCount > 2 && (
                      <>
                        &nbsp; and {attendingCount - (isUserInEvent ? 3 : 2)}
                        {attendingCount === 1 ? " other" : " others"}
                      </>
                    )}
                  </div>
                )}
              </div>
              {attending.map((attendee, i) => {
                if (attendee.message) {
                  return renderAttendee(attendee, i);
                }
              })}
            </>
          )}
        </div>
      </div>
      {isUserInEvent && isLoginEvent && <Navigate to="/home/my-events" />}
      {renderSendInput()}
      <div ref={bottomRef} />
      {addPhoto && (
        <div className="fixed z-40 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex flex-col justify-center items-center">
          <div className="shadow-2xl bg-white w-[500px] h-[500px] rounded-[20px] flex flex-col justify-center items-center sm:w-[45%] w-[85%]  relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setAddPhoto(false)}
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 absolute top-2 right-2 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <div className="relative">
              <div
                className="bg-lightGray bg-cover bg-center w-[80px] h-[80px] rounded-full flex justify-center items-center mx-auto"
                style={{ backgroundImage: `url(${user?.profilePhoto})` }}
              >
                {!user?.profilePhoto && !user.user?.profilePhoto && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div className="text-2xl font-semibold my-4">{user?.displayName}</div>
            <div className="text py-3 pb-5 px-2 text-center opacity-60">
              Add a 📷 so your friends can recognize you!
            </div>
            <input
              type="file"
              onChange={updateProfilePhoto}
              className="flex flex-row items-center justify-center text-sm mt-2 text-slate-500 mx-auto pl-12
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-violet-50 file:text-slate-500
            hover:file:bg-violet-100
          "
            />
            {/* <input type="file" className='mt-4 w-[80%] mx-auto' onChange={updateProfilePhoto} /> */}
            <button
              className="bg-pink font-medium text-white px-4 py-2 rounded-[20px] mt-16
            disabled:opacity-50
            disabled:bg-gray-300
            disabled:cursor-not-allowed"
              onClick={handleAddProfilePhoto}
              disabled={!selectedFile}
            >
              Add Photo
            </button>
          </div>
        </div>
      )}
      {openProfile && (
        <div className="fixed top-0  z-50 left-0 w-full h-full bg-black bg-opacity-50 flex flex-col justify-center items-center">
          <div className="shadow-2xl bg-white w-[500px] h-[500px] rounded-[20px] flex flex-col justify-center items-center sm:w-[45%] w-[85%]  relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setOpenProfile(false)}
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 absolute top-2 right-2 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <div className="relative mt-[-60px]">
              <div className=" grid justify-items-center text-2xl font-semibold my-4">
                {attendeeProfile.displayName}
              </div>
              <div
                className="bg-lightGray bg-cover bg-center w-[250px] h-[250px] rounded-full flex justify-center items-center mx-auto"
                style={{
                  backgroundImage: `url(${attendeeProfile?.profilePhoto})`,
                }}
              >
                {!attendeeProfile?.profilePhoto && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {openAttendingList &&(
        <div className="fixed top-0  z-50 left-0 w-full h-full bg-black bg-opacity-50 flex flex-col justify-center items-center">
        <div className="shadow-2xl bg-white w-[500px] h-[500px] rounded-[20px] flex flex-col justify-center items-center sm:w-[45%] w-[85%]  relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setOpenAttendingList(false)}
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 absolute top-2 right-2 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <div className="relative my-[30px] overflow-y-scroll w-[80%]">
            <div className="text-xl font-medium px-2 pb-4">{attendingCount} going</div>
            {uniqueAttending.map((attendee) => (

              <div className="flex flex-row p-2">
              <div className='w-[35px] h-[35px] mr-4 bg-center shadow-lg bg-cover bg-black rounded-full' style={{
                  backgroundImage: `url(${
                    attendee.profilePhoto || attendee.user?.profilePhoto
                  })`,
              }}></div>
              <div className="text-base opacity-70">{attendee.displayName}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      )}
    </>
  );
}
