// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import "firebase/compat/database";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBl_-a7ybLjQq_9Xp3ClS_nOyTeCEglTCc",
  authDomain: "my-event-1339a.firebaseapp.com",
  projectId: "my-event-1339a",
  storageBucket: "my-event-1339a.appspot.com",
  messagingSenderId: "124564427971",
  appId: "1:124564427971:web:5718a5975fbc585c357e76",
  measurementId: "G-4NZ6XW6WX5"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const functions = getFunctions(app, "us-east4" );


const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage, functions };
