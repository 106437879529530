import React, { useEffect, useState, useRef, useCallback} from 'react';

import "firebase/firestore";
import "firebase/storage";
import { db, auth, storage } from '../firebase';
import { doc, collection, addDoc, setDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { TimepickerUI } from "timepicker-ui";
import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz', 5);




const generateId =(n = 8) =>
  Array(n)
    .fill()
    .map(() => (Math.random() * 10) | 0)
    .join("");

export default function EventForm({ onHideForm }) {
  const formRef = useRef(null);

  const [eventBeingCreated, setEventBeingCreated] = useState(false);
  // Get the current user's ID
  const userId = auth.currentUser.uid;
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(''); // TODO: Add image upload
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');

  const [time, setTime] = useState('12:00 PM');
  const tmRef = useRef(null);

  const testHandler = useCallback(({ detail: { hour, minutes, type } }) => {
    setTime(`${hour}:${minutes} ${type}`);
  }, []);



  useEffect(() => {
    if (time === "10:00 PM") {
      alert("You selected 10:00 PM");
    }
  }, [time]);

  useEffect(() => {
    const tm = tmRef.current;

    const newPicker = new TimepickerUI(tm, {});
    newPicker.create();

    tm.addEventListener("accept", testHandler);

    return () => {
      tm.removeEventListener("accept", testHandler);
    };
  }, [testHandler]);


  async function handleCreateEvent(event) {
    event.preventDefault();
    setEventBeingCreated(true);

    const storageRef = ref(storage, `images/event/${userId}/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    try {
    uploadTask.on('state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      // eslint-disable-next-line default-case
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      // Handle upload errors
      console.error('Error uploading file: ', error);
    },
    async () => {
      // Upload completed successfully, now we can get the download URL
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

      // Create an event in the Firestore collection
      let id = nanoid();
      const newEvent = {
        title,
        image: downloadURL,
        description,
        location,
        date: Timestamp.fromDate(new Date(date.replace(/-/g, '/'))),
        time,
        userId: userId,
        createdAt: Timestamp.fromDate(new Date()),
      };

      await setDoc(doc(db, 'events', id), newEvent);
      // console.log('Event created with ID: ', docRef.id);
      onHideForm();
    }
  );
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  }

  function handleImageChange(event) {
    setImage(event.target.files[0]);
  }


  return (
      <div className='fixed top-0 text-sm left-0 w-full h-full bg-black bg-opacity-50 flex flex-col justify-center items-center'>
        <div className='shadow-2xl bg-white w-[500px]  rounded-[20px] flex flex-col
         justify-center items-center sm:w-[45%] w-[85%] h-[90vh] p-8 relative'>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => onHideForm()}
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 absolute top-2 right-2 cursor-pointer"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
          <input
          type="text"
          placeholder="What is the name of your event?"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          className="border-b-[.2px] focus:outline-none border-darkGray w-[300px] h-[50px] p-2 my-2" />
          <input type="file"
            onChange={handleImageChange}
            className="flex flex-row items-center justify-center text-sm mt-2 text-slate-500 mx-auto
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold
            file:bg-violet-50 file:text-slate-500
            hover:file:bg-violet-100
          "/>
          <textarea
            placeholder="Add description and details about the event (you can use markdown)"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            className="border-[.2px] text-sm focus:outline-none border-darkGray text-gray-700 w-[300px] h-[150px] p-2 my-2"
            style={{ resize: 'vertical' }}
          />
        <input
          type="text"
          placeholder="Where is the event? Address?"
          value={location}
          onChange={(event) => setLocation(event.target.value)}
          className="border-b-[.2px] focus:outline-none border-darkGray w-[300px] h-[50px] p-2 my-2" />
        <input
          type="date"
          placeholder="Date"
          value={date}
          onChange={event => setDate(event.target.value)}
          className="border-b-[.2px] text-darkGray focus:outline-none border-darkGray w-[300px] h-[50px] p-2 my-2" />
          {/* <input
        type="time"
        placeholder="Time"
        value={time}
        onChange={event => setTime(event.target.value)}
        className="border-b-[.2px] focus:outline-none border-darkGray w-[300px] h-[50px] p-2 my-2"
      /> */}
        <div className='timepicker-ui' ref={tmRef}>
          <input
            type='test'
            className='timepicker-ui-input text-darkGray border-b-[.2px] focus:outline-none border-darkGray w-[300px] h-[50px] p-2 my-2'
            defaultValue={time} />
        </div>
          <button type="submit" className=' disabled:bg-gray-400 font-medium border-2 bg-pink text-white text-lg  p-4 w-[60%] rounded-[30px] mt-16'
           onClick={handleCreateEvent}
           disabled={eventBeingCreated}
          >Create Event</button>
        </div>
      </div>
  )
}
