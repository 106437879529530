import React from "react";
import { Link } from "react-router-dom";
import flock from "../components/flocks.png";
import topleft_flock from "../components/topleft_flock.png";
import {auth} from '../firebase';

export default function Layout({user}){
  return (
    <div className="bg-white flex flex-1 h-screen flex-col items-center justify-center opacity-100 ">
      <img src={topleft_flock} alt="flock"  className=" max-w-[500px] ml-[-106px]"/>
      <div className='text-darkGray opacity-80'>Invite your friends without the hassle.</div>

      {/* <div className='flex flex-row'>
        <div className='text-black m-auto text-4xl tracking-[2px] opacity-90'>FLOCK</div>
        <div className='waviy text-black ml-8 tracking-[2px]'>
          {['T', 'O', 'G', 'E', 'T', 'H', 'E', 'R'].map((char, index) => (
            <span key={index} style={{'--i': index+1}}>{char}</span>
          ))}
        </div>
      </div> */}
      {user &&(
        <Link to='/home/my-events'>
          <div className='custom-shadow px-4 py-2 mt-2 font-medium'>My Flocks</div>
        </Link>
      )}
      {!user &&(
      <div className='mt-10 flex flex-row'>
          <Link to='/login' >
            <div className='custom-shadow px-4 py-2'>Log In</div>
          </Link>
          <Link to='/signup'>
            <div className='custom-shadow px-4 py-2'>Sign Up</div>
          </Link>
      </div>
      )}
    </div>
  );
}
