import "./App.css";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Login from "./routes/Login";
import Signup from "./routes/Signup";
import Home from "./routes/Home";
import Layout from "./routes/Layout";
import { useState } from "react";
import MyEvents from "./components/MyEvents";
import Explore from "./components/Explore";
import Upcoming from "./components/Upcoming";
import Settings from "./components/Settings";
import ExternalEvent from "./components/ExternalEvent";
import { useStore } from "./store";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "./firebase";
import usePageTracking from './usePageTracking';

import { ChakraProvider } from "@chakra-ui/react";

export function Plane(){
return <svg
xmlns="http://www.w3.org/2000/svg"
fill="none"
viewBox="0 0 24 24"
strokeWidth={1.5}
stroke="currentColor"
className="w-6 h-6"
>
<path
  strokeLinecap="round"
  strokeLinejoin="round"
  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
/>
</svg>}


function App() {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  // console.log("user Routing----", user)

  const currentMessage = useStore((state) => state.currentMessage);
  const setCurrentMessage = useStore((state) => state.setCurrentMessage);

  useEffect(() => {
    onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        onSnapshot(doc(db, "users", authUser.uid), (doc) => {
          setUser(doc.data() || authUser); // changed it here to authUser
        });
      } else {
        setUser(null);
      }
    });
  }, [setUser]);

  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          {user ? (
            <Route path="/" element={<Navigate to="/home" replace />} />
          ) : (
            <Route path="/" index element={<Layout />} />
          )}
          <Route path="login" element={<Navigate to ="/flock/admin"/>} />
          <Route path="signup" element={<Navigate to ="/flock/admin"/>} />
          <Route path="flock" element={<Layout user={user} />} />
          <Route path="flock/:eventId" element={<ExternalEvent />} />
          {user && (
            <Route path="home" element={<Home user={user} />}>
              <Route path='my-events' element={<MyEvents />} />
              <Route index element={<Upcoming />} />
              <Route path="explore" element={<Explore />} />
              <Route path="upcoming" element={<Upcoming />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          )}
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}
          // <Route path="/:eventTitle" element={<ExternalEvent />} />

export default App;
