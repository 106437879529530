import { auth, db } from "../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import parsePhoneNumber from "libphonenumber-js";
import classNames from "classnames";
import {animateScroll } from 'react-scroll'

import { Plane } from '../App';
import { motion } from "framer-motion";
import { LiaSpinnerSolid } from "react-icons/lia";
import { useState, useEffect } from "react";
import { useRef } from "react";
import { setDoc, doc, collection } from "firebase/firestore";
import { query, where, getDocs } from "firebase/firestore";
import { Button } from "@chakra-ui/react";
import { useStore } from "../store";
import { addEventToUser } from "../firebase.api";

function Lock() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#d88"
      className="opacity-80 w-6 h-6 ml-0"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
      />
    </svg>
  );
}

export default function PhoneVerificationForm({ onFinish }) {
  const currentName = useStore((state) => state.currentName);
  const setCurrentName = useStore((state) => state.setCurrentName);
  const [nameEntered, setNameEntered] = useState(false);

  const state = useStore((state) => state);

  const [phoneSent, setPhoneSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);

  const formRef = useRef(null);
  const telRef = useRef(null);
  const verificationCodeRef = useRef(null);
  const nameRef = useRef(null);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.focus();
    }
    if (telRef.current && nameEntered) {
      telRef.current.focus();
    }
    if (verificationCodeRef.current && verificationCodeSent) {
      verificationCodeRef.current.focus();
    }
    if (nameRef.current && !nameEntered) {
      nameRef.current.focus();
    }
  }, [nameEntered, verificationCodeSent]);

  const onSendPhoneNumber = (event) => {
    event.preventDefault();

    const appVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );

    const parsed = parsePhoneNumber(phoneNumber, "US");
    animateScroll.scrollToBottom();
    signInWithPhoneNumber(auth, parsed.number, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setVerificationCodeSent(true);
        setPhoneSent(false);
      })
      .catch((error) => {
        console.log(error);
      });

    setPhoneSent(true);
  };

  const handleVerification = (event) => {
    event.preventDefault();
    const verificationCodeInput = verificationCode.trim();
    window.confirmationResult
      .confirm(verificationCodeInput)
      .then(async (result) => {
        // User signed in successfully.
        const authUser = result.user;
        //putting this on the object because we are going to send it back to add the message
        authUser.displayName = currentName;
        state.setUser(authUser);
        console.log("state", state.user);
        // check if user exists in Users collection with phone number
        const snapshot = await getDocs(
          query(
            collection(db, "users"),
            where("phoneNumber", "==", authUser.phoneNumber)
          )
        );
        if (snapshot.empty) {
          // add User to Users collection
          let newUser = {
            phoneNumber: authUser.phoneNumber,
            id: authUser.uid,
            displayName: currentName,
          };
          const docRef = doc(db, "users", authUser.uid);
          const result = await setDoc(docRef, newUser);
        }
        //done
        console.log("finished");
        //sending this back to use the user to add the message
        //TODO: FIX THIS HACKITY
        onFinish(authUser);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <form ref={formRef}>
      {/* <div id="sign-in-button"></div> */}
      <div className="py-2 text-[17px] text-slate-600 font-medium flex mx-auto w-fit max-w-[85%]">
        {nameEntered ? (
          <>
            {verificationCodeSent ? (
              <>
                We sent you a text message with a verification code.
                <br />
                <br />
                Enter the code below and you're all set!
              </>
            ) : (
              <motion.div layout>
                👋 &nbsp;<span className="font-bold">{currentName}</span>!
                <div className="pt-3">
                  Just{" "}
                  <span className="text-black font-bold">
                    enter your number
                  </span>{" "}
                  to finish your sign up.
                </div>
              </motion.div>
            )}
          </>
        ) : (
          <div>
            <div className="pb-4">You are almost there!</div>
            <div>
              <span className="font-bold text-black">Add your name</span> to introduce yourself.
            </div>
          </div>
        )}
      </div>
      <div
        className="border bg-white border-[2.5px] border-sky-600 text-darkGray
        border-2 p-2 text-[18px] rounded-[20px] w-[95%] flex flex-row justify-between mx-auto"
      >
        {!verificationCodeSent && (
          <>
            {!nameEntered ? (
              <>
              <Lock/>
                <input
                  ref={nameRef}
                  className="focus:outline-none w-full pl-1 text-black "
                  placeholder="Your name"
                  style={{ background: "none" }}
                  type="text"
                  enterKeyHint="next"
                  id="message"
                  value={currentName}
                  required
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    setCurrentName(inputValue);
                  }}
                />
                <button
                type="submit"
                className="hover:text-black"
                onClick={(e) => {
                  e.preventDefault();
                  setNameEntered(true);
                }}
              >
                <Plane/>
                </button>

              </>
            ) : (
              <>
              <Lock/>
                <input
                  ref={telRef}
                  className="focus:outline-none w-full pl-1 text-black "
                  placeholder="Enter your phone number..."
                  type="tel"
                  enterKeyHint="next"
                  value={phoneNumber}
                  required
                  onChange={(event) => setPhoneNumber(event.target.value)}
                />
                <button
                  className={classNames("hover:text-black")}
                  type="submit"
                  onClick={onSendPhoneNumber}
                >
                  {phoneSent ? (
                    <LiaSpinnerSolid />
                  ) : (
                  <Plane />
                 )}
                </button>
              </>
            )}
          </>
        )}
        {verificationCodeSent && (
          <>
              <Lock />
            <input
              className="focus:outline-none w-full pl-1 text-black "
              ref={verificationCodeRef}
              style={{ background: "none" }}
              type="number"
              inputmode="number"
              enterKeyHint="send"
              id="verificationCode"
              placeholder="Enter your verification code..."
              value={verificationCode}
              onChange={(event) => setVerificationCode(event.target.value)}
              required
            />
            <button className="hover:text-black" onClick={handleVerification}>
              <Plane/>
            </button>
          </>
        )}
      </div>
      {/* <div id="sign-in-button"></div> */}
    </form>
  );
}
