import { db } from "./firebase";
import { doc, updateDoc, getDocs, arrayUnion , collection, Timestamp, getDoc, addDoc, serverTimestamp} from "firebase/firestore";
import { update } from "lodash";
import { useStore } from "zustand";


export async function getAttendeeNumbers(ids) {
  const usersRef = collection(db, 'users');
  const snapshot = await getDocs(usersRef);
  const users = snapshot.docs.map(doc => doc.data());
  const attendees = users.filter(user => ids.includes(user.id));
  const numbers = attendees.map(attendee => attendee.phoneNumber);
  console.log( numbers);
  return numbers;
}

export async function addMessageToCollection(numbers, message, eventId, host) {
  const messagesRef = collection(db, 'messages');
  const body = `${host.displayName}: ${message}  \n\n 🐦\n Reply in the thread @ https://flk.app/flock/${eventId}/?b=1`
  const promises = numbers.map((number) => {
    return addDoc(messagesRef, {
      to: number,
      body: body,
      createdAt: serverTimestamp(),
    });
  });

  try {
    await Promise.all(promises);
    console.log("Messages added to collection successfully");
    return true;
  } catch (err) {
    console.error("Error adding message: ", err);
    return false;
  }
}



export async function addUserToEvent(user, eventId, message) {
    //code smell!
    let userId = user.id ? user.id : user.uid;
    const eventRef = doc(collection(db, 'events'), eventId);
    // TO DO: add the whole user to the
    await updateDoc(eventRef, {
      attending: arrayUnion({
        userId: userId,
        joinedAt: Timestamp.fromDate(new Date()),
        message: message,
      }),
    });
    console.log("User added to event successfully");
    return true;
}

export async function getUserEvents(userId) {
  console.log(userId, "userId")
    const userRef = doc(collection(db, 'users'), userId);
    const userSnap = await getDoc(userRef);
    const user = userSnap.data();
    const eventIds = user.events;
    const eventsPromises = eventIds.map(async (eventId) => {
      const eventRef = doc(collection(db, 'events'), eventId);
      const eventSnap = await getDoc(eventRef);
      if (!eventSnap.exists()) return null;

      const event = eventSnap.data();
      event.id = eventSnap.id;
      return event;
    });

    const events = await Promise.all(eventsPromises);
    return events.filter(e => e);
}



export async function addEventToUser(user, eventId) {
    const userId = user.id ? user.id : user.uid;
    const userRef = doc(collection(db, 'users'), userId);
    await updateDoc(userRef, {events: arrayUnion(eventId)});
    console.log("Event added to user successfully");
}

export async function updateDisplayName(user, displayName) {
    console.log(displayName, "--------displayName")
    const userRef = doc(collection(db, 'users'), user.id);
    let updated = await updateDoc(userRef, {displayName: displayName});
    if (updated){
      console.log("User displayName updated successfully");
      return true;
    }
}

export async function getEvents(){
  // const events = useStore(state => state.events);
  const eventsQuery = collection(db, 'events');
  const snapshot = await getDocs(eventsQuery);
  const events = snapshot.docs.map(doc => doc.data());
  // console.log("events", events)
  return events;

}

getEvents();
