import React, { useState, useEffect } from 'react';
import {db, auth }  from '../firebase';
import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { formatDate, signInEventId } from '../utils';
import _ from "lodash";

import { getUserEvents } from '../firebase.api';



export default  function Explore() {
  console.log("here")

  const [events, setEvents] = useState([]);
  const currentDate = new Date();
  const userId = auth.currentUser?.uid;
  console.log("userId", userId)
  useEffect(() => {
    const fetchUserEvents = async () => {
      try {
        if (userId) {
          const userEvents = await getUserEvents(userId); // Pass the userId parameter
          console.log("upcoming events' userEvents:", userEvents);
          setEvents(userEvents.filter(event => event.id !== signInEventId));
        }
      } catch (error) {
        console.log("Error fetching user events:", error);
      }
    };

    fetchUserEvents();
  }, [userId]);





  return (

    <div className='flex flex-1 flex-col px-10'>
      <div className="flex flex-row sm:pt-24 py-10 uppercase text-lg font-semibold h-fit">
        <div className="pr-6 h-fit">Going to </div>
      </div>
      <div className='grid sm:flex-1 sm:grid-cols-3 gap-10'>
        {events.filter(event => event.date?.toDate() > currentDate)?.map((event) => (
         <Link to={`/flock/${event.id}`} className='w-fit'>
            <div
            key={event.id}
            className='w-60 h-56 shadow hover:border text-white font-semibold  bg-center text-2xl cursor-pointer'
            style={{ backgroundImage: `url(${event.image})`, backgroundSize: 'cover'}}
            >
            </div>
            <div className='flex flex-col text-black mt-2'>

            <div className='uppercase'>
              {event.title}
            </div>
              <div  className='flex flex-row '>
                {_.uniq(event?.attending?.map((a) => a.userId)).length > 0 && (
                <>
                  <div className='flex flex-row opacity-60 items-center'>
                    <div className=''> &nbsp; {_.uniq(event?.attending?.map((a) => a.userId)).length}</div>
                    <div className='ml-1 text-sm'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                      </svg>
                    </div>
                  </div>
                  <div className='flex flex-row opacity-60 items-center'>
                      <div className=''> &nbsp; {event?.attending.map((a) => a.userId).length}</div>
                      <div className='ml-1 text-sm'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                        </svg>
                      </div>
                  </div>
                  </>
                )}
              </div>
              <div className=''>
                {formatDate(event.date.toDate())}
              </div>
              <div className='text-sm'>
                {event.time}
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="flex flex-row sm:pt-24 py-10 uppercase text-lg font-semibold h-fit">
        {events.filter(event => event.date?.toDate() < currentDate).length > 0 && (
          <div className="pr-6 h-fit">Went to </div>
        )}
      </div>
      <div className='grid sm:flex-1 sm:grid-cols-3 gap-10'>
        {events.filter(event => event.date?.toDate() < currentDate).map((event) => (
         <Link to={`/flock/${event.id}`} className='w-fit'>
            <div
            key={event.id}
            className='w-60 h-56 opacity-50  p-6 shadow text-white font-semibold text-2xl cursor-pointer'
            style={{ backgroundImage: `url(${event.image})`, backgroundSize: 'cover'}}
            >
            </div>
            <div className='flex flex-col text-black mt-2'>
              <div className='flex flex-row'>
              <div className='uppercase'>
                {event.title}
              </div>
              </div>
              {event.attending?.length > 0 && (
                <div className='flex flex-row opacity-60 items-center'>
                  <div className=''> &nbsp; {_.uniq(event?.attending?.map((a) => a.userId)).length}</div>
                  <div className='ml-1'>
                    <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                    </svg>
                  </div>
                </div>
              )}
              <div className=''>
                {formatDate(event.date.toDate())}
              </div>
              <div className='text-sm'>
                {event.time}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
